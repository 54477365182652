import acceptLanguage from 'accept-language'
import { NextRequest, NextResponse } from 'next/server'
import { fallbackLng, languages } from './app/i18n/settings'

acceptLanguage.languages(languages)

export const config = {
  matcher: [
    '/((?!api|uapi|_next/static|_next/image|assets|favicon.ico|opengraph-image.png|sw.js).*)',
  ],
}

const domainLanguageMap: Record<string, string> = {
  'st-fr-fructidor.app.ubq.network': 'fr',
  'pr-es-fructidor.app.ubq.network': 'es',
  'pr-it-fructidor.app.ubq.network': 'it',
  'pr-fr-fructidor.app.ubq.network': 'fr',
  'pr-en-fructidor.app.ubq.network': 'en',
  'fructidor.fr': 'fr',
  'fructidor.it': 'it',
  'fructidor.es': 'es',
}

export const getLanguageFromPathname = (pathname: string) => {
  const matchedLanguage = languages.find((lang) =>
    pathname?.startsWith(`/${lang}`)
  )
  return matchedLanguage || 'en'
}

export const removeLanguageCode = (url: string): string => {
  const pathParts = url.split('/')
  if (languages.includes(pathParts[1])) pathParts.splice(1, 1)
  return pathParts.join('/')
}

export function middleware(request: NextRequest) {
  const { pathname } = request.nextUrl
  const { cookies } = request
  const domain = request.headers.get('x-forwarded-host') ?? ''

  // Redirect www.fructidor.com to fructidor.com
  if (domain == 'www.fructidor.com') {
    return NextResponse.redirect('https://fructidor.com')
  }

  const headers = new Headers(request.headers)
  headers.set('current-path', request.nextUrl.pathname)

  // Check if pathname already contains a language prefix
  const hasLangInPath = languages.some((loc) => pathname?.startsWith(`/${loc}`))
  const lng = getLanguageFromPathname(pathname) ?? fallbackLng
  const domainLanguage = domainLanguageMap[domain]

  if (pathname.includes('icon') || pathname.includes('chrome')) {
    return NextResponse.next({ headers })
  }

  // Handle language-based redirection based on domain mapping needed stage in all env but prod
  if (domainLanguage && !request.nextUrl.pathname?.startsWith('/_next')) {
    if (!hasLangInPath || (hasLangInPath && lng !== domainLanguage)) {
      return NextResponse.redirect(
        new URL(
          `/${domainLanguage}/${removeLanguageCode(pathname)}`,
          request.url
        )
      )
    }
  } else if (
    !hasLangInPath &&
    !request.nextUrl.pathname?.startsWith('/_next')
  ) {
    const urlPath = `/${lng}${pathname}`
    return NextResponse.redirect(new URL(urlPath, request.url))
  }
  return NextResponse.next({ headers })
}
