import { AllMembersType } from '@/app/types/memberType'
import { getMembersList } from '@/services/generalDataServices'
import { createSlice } from '@reduxjs/toolkit'
import { languageTransition } from '@/app/utils/language'

export interface SignInResponse {
  id: number
  email: string
  title: string
  first_name: string
  last_name: string
  phone: string
  is_company: boolean
  subscription: string
  company: Company
  company_activities: string[]
  role: string
}

export interface Company {
  id: number
  name: string
  languages: Language[]
}
languageTransition
export interface Language {
  id: number
  name: string
  locale: string
  created_at: string
  updated_at: string
}

interface UserState {
  userEmail: string
  userId: number
  companyName: string
  companyId: number
  userName: string
  userSubscription: string
  firstName: string
  lastName: string
  activity: { id: string; name: string }[]
  phoneNo: number
  phoneCode: string
  phoneCodeId: number
  role: string
  department: { id: string; name: string }
  userPosition?: { id: string; name: string }
  companyCountry: { id: string; name: string }
  membersList: any
  loading: boolean
  error: string
  title: string
  localeLangauge: string
  userRole: string
  allMembersList: AllMembersType
  companyIndustry: string
  is_company: boolean
  friendly_url: string
  loggedIn: boolean
  isAllCookiesEnabled: boolean
}

const initialState: UserState = {
  userEmail: '',
  userId: 0,
  companyName: '',
  companyId: 0,
  userName: '',
  userSubscription: '',
  firstName: '',
  lastName: '',
  activity: [],
  phoneNo: 0,
  phoneCode: '',
  phoneCodeId: -1,
  department: { id: '', name: '' },
  userPosition: { id: '', name: '' },
  companyCountry: { id: '', name: '' },
  role: '',
  membersList: [],
  loading: true,
  error: '',
  title: '',
  localeLangauge: 'English',
  userRole: '',
  allMembersList: {
    members: [],
    unverified_members: [],
  },
  companyIndustry: '',
  is_company: false,
  friendly_url: '',
  loggedIn: false,
  isAllCookiesEnabled: false,
}
export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload
    },
    setLastName: (state, action) => {
      state.lastName = action.payload
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload
    },
    setLocaleLangauge: (state, action) => {
      languageTransition.forEach((item) => {
        if (item?.path == action.payload) {
          state.localeLangauge = item?.language
        }
      })
    },
    setEmail: (state, action) => {
      state.userEmail = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setName: (state, action) => {
      state.userName = action.payload
    },
    setUserID: (state, action) => {
      state.userId = action.payload
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload
    },
    setSubscription: (state, action) => {
      state.userSubscription = action.payload
    },
    setLoggedIn: (state, action) => {
      state.loggedIn = action.payload
    },
    setCurrentUser: (state, action) => {
      state.userEmail = action?.payload?.email
      state.userId = action?.payload?.id
      state.companyName = action?.payload?.company?.name
      state.firstName = action?.payload?.first_name
      state.lastName = action?.payload?.last_name
      state.phoneNo = action?.payload?.phone
      state.phoneCode = action?.payload?.phone_code
      state.phoneCodeId = action.payload?.phone_code_id ?? -1
      state.role = action?.payload?.role ? action?.payload?.role : ''
      state.userPosition = action.payload?.position ?? ''
      state.department = action.payload?.department?.id ?? ''
      state.activity = action.payload?.company_activities ?? []
      state.companyCountry = action?.payload?.company?.country
      state.userSubscription = action?.payload?.subscription
      state.title = action?.payload?.title
      state.companyId = action?.payload?.company?.id
      state.companyIndustry = action.payload?.industry
      state.is_company = action.payload?.is_company
      state.friendly_url = action?.payload?.company?.friendly_url ?? ''
    },
    setUser: (state, action) => {
      state.userEmail = action?.payload?.email
      state.userId = action?.payload?.id
      state.companyName = action?.payload?.company?.name
      state.firstName = action?.payload?.first_name
      state.lastName = action?.payload?.last_name
      state.phoneNo = action?.payload?.phone
      state.phoneCode = action?.payload?.phone_code
      state.phoneCodeId = action.payload?.phone_code_id ?? -1
      state.role = action?.payload?.role ? action?.payload?.role : ''
      state.userPosition = action.payload?.position ?? ''
      state.department = action.payload?.department?.id ?? ''
      state.activity = action.payload?.company_activities ?? []
      state.companyCountry = action?.payload?.company?.country
      state.userSubscription = action?.payload?.subscription
      state.title = action?.payload?.title
      state.companyId = action?.payload?.company?.id
      state.companyIndustry = action.payload?.industry
      state.is_company = action.payload?.is_company
      state.friendly_url = action?.payload?.company?.friendly_url ?? ''
      state.loggedIn = true
    },
    setAllMembersList: (state, action) => {
      state.membersList = action.payload
    },
    setIsAllCookiesEnabled: (state, action) => {
      state.isAllCookiesEnabled = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMembersList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getMembersList.fulfilled, (state, action: any) => {
      state.loading = false
      state.membersList = action?.payload?.members
      state.allMembersList = action.payload
    })
    builder.addCase(getMembersList.rejected, (state, action: any) => {
      state.loading = false
      state.error = action.payload
    })
  },
})

export const {
  setName,
  setFirstName,
  setLastName,
  setUserID,
  setSubscription,
  setCompanyName,
  setCurrentUser,
  setUser,
  setUserRole,
  setAllMembersList,
  setLoggedIn,
  setLocaleLangauge,
  setLoading,
  setIsAllCookiesEnabled,
} = userSlice.actions
export default userSlice.reducer
